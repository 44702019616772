<template>
  <div class="payment-code">
    <van-nav-bar title="付款码" left-arrow @click-left="onClickLeft" />
    <div class="content df fdc">
      <div class="avatar">
        <img
          src="../../assets/images/xg/img1.png"
          style="width: 100px; height: 100px"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-nav-bar__content {
  height: 80px;
  line-height: 80px;
  background: #379f00;
}
::v-deep .van-nav-bar__title {
  font-size: 36px;
  color: #fff;
}
::v-deep .van-icon {
  color: #fff;
}
.payment-code {
  background: #379f00;
  height: 100%;
  .content {
    width: 690px;
    height: 800px;
    background: #fff;
    border-radius: 20px;
    .avatar {
      width: 170px;
      height: 170px;
      border-radius: 50%;
      background: #fff;
    }
  }
}
</style>
